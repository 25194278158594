
<template>
  <div class="page">
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
      <div class="list" v-if="recentWorkDate.length>0">
        <div class="item" v-for="item in recentWorkDate" :key="item.id" @click="navToFun(item.id, item.title, item.categoryName)">
          <div class="top">
            <span class="time">{{ item.createTime }}</span>
            <span class="status text-red" v-if="item.finishStatus == 1">未完成</span>
            <span class="status text-blue" v-else>已完成</span>
          </div>
          <div class="bottom">
            <div class="content">{{ item.title }}</div>
            <div class="name text-blue">认领人:{{ item.getUser || "未知" }}</div>
          </div>
          <div class="arrow">
            <van-icon name="arrow" size="14" />
          </div>
        </div>
      </div>
      <van-empty description="暂无数据" v-else />
    <!-- </van-pull-refresh> -->
    <div class="addBtn" @click="addRecentWork()">
      <img :src="require('@/assets/img/add.png')" style="width: 100%; height: 100%" />
    </div>
    <!-- <div class="addBtn" @click="addRecentWork()">
      <van-button round type="info">
        <van-icon name="plus" />
        <div>添加</div>
      </van-button>
    </div> -->
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate } from "@/utils/utils";
import { Toast } from 'vant';

export default {
  components: {
    topBar,
  },
  data() {
    return {
      category: "0",
      recentWorkDate: [],
      currentDate: "",
      loading: true,
      isLoading: false,
    };
  },
  methods: {
    onRefresh() {
      this.getDataList(this.$route.query.category)
      this.isLoading = false;
    },
    getDataList(category) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqworktarget/list"),
        method: "post",
        params: this.$http.adornParams({
          page: 0,
          limit: 1000,
          category: category,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.recentWorkDate = data.page.list;
            // 加载状态结束
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    addRecentWork() {
      this.$router.push({
        name: "gridAddRecentWork",
        query: {
          title: this.$route.query.title,
          category: this.$route.query.category,
        },
      });
    },
    navToFun(userId, title, categoryName) {
      this.$router.push({
        name: "gridRecentWorkInfo",
        query: { userId, title, categoryName },
      });
    },
  },
  mounted() { },
  created() {
    this.currentDate = formatterDate(new Date());
    this.getDataList(this.$route.query.category);
  },
};
</script>
<style scoped lang="scss" >
.page {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 0 100px 0;
  box-sizing: border-box;
  background: #f5f5f5;
  font-size: 24px;
  .list {
    padding: 30px;
    .item {
      position: relative;
      width: 100%;
      background: #fff;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        .time {
          color: #999;
        }
      }
      .bottom {
        padding: 30px 30px 0 0;
        .content {
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 32px;
        }
        .name {
          margin-top: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
        }
      }
      .arrow {
        position: absolute;
        right: 10px;
        top: 50%;
      }
    }
  }
}

.addBtn {
  position: fixed;
  right: 30px;
}
.van-button {
  width: 150px;
  height: 150px;
}
</style>
